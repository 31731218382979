<template lang="pug">
  div
    base-snackbar(
      :errors.sync="orderGetErrors"
    )
    v-data-table(
      :items="orderList"
      :headers="headers"
      :loading="orderGetting"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :server-items-length="serverItemsLength"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      single-expand
      dense
    )
      template(v-slot:top)
        //- tr
        //-   td(:colspan="headers.length")
        v-row
          v-col(offset-lg="8" lg="2" cols="6")
            v-btn(
              color="purple"
              dark
              block
              dense
              small
              @click="exportOrderDialog = true"
            )
              span Export Orders
          v-col(lg="2" cols="6")
            //- export-order2(
            //-   v-model="orderList"
            //- )
            v-btn(
              color="green"
              dark
              block
              dense
              @click="exportItemDialog = true"
              small
            )
              span Export Items
          v-col.pb-0(cols="4")
            v-combobox.ma-0.pa-0(
              label="search"
              append-icon="mdi-magnify"
              v-model="searches"
              :clearable="searches.length"
              multiple
            )
              template(v-slot:selection="{ item, parent, selected }")
                v-chip(
                  color="blue-grey darken-3"
                  dark
                  :input-value="selected"
                  label
                  small
                )
                  span {{ item }}
                  v-icon(small @click="deleteItem(item)") $delete
          v-col(cols="1")
            v-autocomplete(
              label="Month"
              dense
              v-model="month"
              :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
              clearable
            )
          v-col(cols="1")
            v-autocomplete(
              label="Year"
              dense
              :items="[2022, 2021]"
              v-model="year"
              clearable
            )
          v-col(cols="3")
            v-autocomplete(
              dense
              label="Revenue Source"
              v-model="revenueSources"
              :items="revenueSourceIndexList"
              :loading="revenueSourceIndexGetting"
              item-value="id"
              item-text="code"
              multiple
              small-chips
              clearable
            )
              template(v-slot:item="{ item }")
                span.primary--text.mr-2 {{ item.code }}
                small.blue-grey--text {{ item.description }}
          v-col(cols="12")
            v-divider
      template(v-slot:item="{ item, expand, isExpanded }")
        tr
          td {{ item.customer ? $autoAbbriviate(item.customer.company) : 'N/A' }}
          td {{ $intToInvoiceNumber(item.invoice ? item.invoice.id : 0) }}
          td {{ item.order_date.dateFormat() }}
          td(align="right") {{ (item.total_amount || 0).phpFormat() }}
          td {{ item.status ? item.status.name : 'N/A' }}
          td.text-right {{ balance(item).phpFormat() }}
          td {{ item.updated_at.dateFormat({ hour: '2-digit', minute: '2-digit' }) }}
          td
            v-btn(
              v-if="item.order_status_id === 'NW'"
              text
              x-small
              color="primary"
              @click="changeStatusToDelivered(item.id)"
            )
              v-icon mdi-truck-delivery-outline
            v-btn(
              v-if="!item.invoice_count"
              text
              x-small
              color="yellow darken-3"
              @click="$router.push({ name: 'orders.edit', params: { orderId: item.id } })"
            )
              v-icon mdi-pencil
            v-btn(
              v-else
              text
              x-small
              color="purple darken-3"
              @click="$router.push({ name: 'orders.show', params: { orderId: item.id } })"
            )
              v-icon mdi-eye
            v-btn(
              text
              x-small
              color="blue-grey darken-3"
              @click="expand(!isExpanded)"
            )
              v-icon(:class="!isExpanded || 'is-expanded'") mdi-chevron-down
      template(v-slot:expanded-item="{ item }")
        tr
          td(:colspan="headers.length")
            v-simple-table(dense)
              template(v-slot:default)
                thead
                  tr
                    th Stock
                    th Quantity
                    th Price
                    th Discount
                    th Amount
                tbody
                  tr(v-for="detail in item.details" :key="detail.id")
                    td {{ (detail.stock || {}).description || 'N/A' }}
                    td {{ detail.quantity }} {{ detail.unit }}
                    td {{ (detail.unit_price || 0).phpFormat() }}
                    td {{ (detail.discount || 0).phpFormat() }}
                    td {{ detailAmount(detail) }}
    v-dialog(
      v-model="exportItemDialog"
      eager
      width="400"
      persistent
    )
      v-card
        v-toolbar(
          color="purple"
          dark
          dense
          flat
        )
          span Export Items
        v-container
          v-row
            v-col(cols="6").ma-0.pb-0
              v-autocomplete(
                dense
                label="Customer"
                v-model="exportItemsFilter.customerId"
                :items="customerIndexList"
                :loading="customerIndexGetting"
                item-value="id"
                item-text="company"
                clearable
              )
                template(v-slot:selection="{ item }")
                  span.primary--text {{ item.id }}
                  small.blue-grey--text.ml-2 {{ item.company }}
                template(v-slot:item="{ item }")
                  span.primary--text {{ item.id }}
                  small.blue-grey--text.ml-2 {{ item.company }}
            v-col(cols="6").ma-0.pb-0
              v-autocomplete(
                dense
                label="Revenue Source"
                v-model="exportItemsFilter.revenueSource"
                :items="revenueSourceIndexList"
                :loading="revenueSourceIndexGetting"
                item-value="id"
                item-text="description"
                multiple
                small-chips
                clearable
              )
            v-col(cols="6").ma-0.py-0
              v-autocomplete(
                dense
                label="Month"
                v-model="exportItemsFilter.month"
                :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                clearable
              )
            v-col(cols="6").ma-0.py-0
              v-autocomplete(
                dense
                label="Year"
                v-model="exportItemsFilter.year"
                :items="[2021, 2022, 2023]"
                clearable
              )
            v-col(cols="6").ma-0.py-0
              v-autocomplete(
                multiple
                label="Status"
                small-chips
                dense
                v-model="exportItemsFilter.status"
                :items="orderStatusIndexList"
                item-value="id"
                clearable
                item-text="name"
              )
            v-col(cols="6").ma-0.py-0
              v-autocomplete(
                multiple
                label="Category"
                small-chips
                dense
                v-model="exportItemsFilter.stockCategory"
                :items="stockCategoryIndexList"
                item-value="id"
                clearable
                item-text="name"
              )
        v-card-action
          v-btn(
            text
            color="green"
            :loading="orderDetailExportV1Getting"
            @click="exportDetailV1"
          )
            span Export
          v-btn(
            text
            color="red"
            @click="exportItemDialog = false"
          )
            span Close
    export-order(
      v-model="exportOrderDialog"
      :customer-index-list="customerIndexList"
      :order-status-index-list="orderStatusIndexList"
      :revenue-source-index-list="revenueSourceIndexList"
    )
</template>
<style lang="scss" scope>
  .v-btn.is-expanded .v-icon {
    transform: rotate(-180deg);
  }

</style>
<script>
import orderRepository from '@/repositories/order.repository'
import stockCategoryRepository from '@/repositories/stock-category.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import customerRepository from '@/repositories/customer.repository'
import orderStatusRepository from '@/repositories/order-status.repository'
import orderDetailRepository from '@/repositories/order-detail.repository'
import revenueSourceRepository from '@/repositories/revenue-source.repository'
import { requestVars } from '@/libs/api-helper.extra'

const [orderGetVars, orderGetVarNames] = requestVars({ identifier: 'order' })
const [orderDetailExportV1Vars, orderDetailExportV1VarNames] = requestVars({ identifier: 'order-detail-export-v1', hasData: false })
const [customerIndexVars, customerIndexVarNames] = requestVars({ identifier: 'customer-index' })
const [orderStatusIndexVars, orderStatusIndexVarNames] = requestVars({ identifier: 'order-status-index' })
const [revenueSourceIndexVars, revenueSourceIndexVarNames] = requestVars({ identifier: 'revenue-source-index' })
const [stockCategoryIndexVars, stockCategoryIndexVarNames] = requestVars({ identifier: 'stock-category-index' })
const [orderDeliverVars, orderDeliverVarNames] = requestVars({ identifier: 'order-deliver', hasData: false })

const orderGetHandler = new VueRequestHandler(null, orderGetVarNames)
const orderDetailExcelV1Handler = new VueRequestHandler(null, orderDetailExportV1VarNames)
const customerIndexHandler = new VueRequestHandler(null, customerIndexVarNames)
const orderStatusIndexHandler = new VueRequestHandler(null, orderStatusIndexVarNames)
const revenueSourceIndexHandler = new VueRequestHandler(null, revenueSourceIndexVarNames)
const stockCategoryIndexHandler = new VueRequestHandler(null, stockCategoryIndexVarNames)
const orderDeliverHandler = new VueRequestHandler(null, orderDeliverVarNames)

const tableVars = () => ({
  headers: [
    // { text: 'Employee', value: 'employee_id' },
    { text: 'Customer', value: 'customer_id' },
    { text: 'Invoice No.', value: 'invoice_number' },
    { text: 'Order Date', value: 'order_date' },
    { text: 'Amount', value: 'total_amount' },
    { text: 'Status', value: 'order_status_id' },
    { text: 'Balance', value: 'balance' },
    { text: 'Updated At', value: 'updated_at' },
    { text: 'Config', sortable: false },
  ],
  searches: [],
  page: 1,
  itemsPerPage: 10,
  serverItemsLength: 0,
  sortBy: ['invoice_number'],
  sortDesc: [true],
  year: null,
  month: null,
  revenueSources: [],
  exportItemsFilter: {
    revenueSource: [],
    status: [],
    stockCategory: [],
  },
  exportOrderDialog: false,
  exportItemDialog: false,
})
export default {
  name: 'CustomerOrderTable',
  props: ['tableFilters'],
  components: {
    exportOrder: () => import('./ExportOrder'),
    exportOrder2: () => import('./Export2'),
  },
  data: () => ({
    ...tableVars(),
    ...orderGetVars,
    ...orderDetailExportV1Vars,
    ...customerIndexVars,
    ...orderStatusIndexVars,
    ...revenueSourceIndexVars,
    ...stockCategoryIndexVars,
    ...orderDeliverVars,
  }),
  created() {
    this.initializeWebsocket()
    this.getOrders()
    this.getCustomerIndex()
    this.getOrderStatuses()
    this.getRevenueSources()
    this.loadTableFilters()
    this.getStockCategories()
  },
  computed: {
    search() {
      return this.searches.join(',')
    },
  },
  watch: {
    revenueSources() {
      this.page = 1
      this.getOrders()
      this.updateTableFilters()
    },
    search() {
      this.page = 1
      this.getOrders()
      this.updateTableFilters()
    },
    page() {
      this.getOrders()
      this.updateTableFilters()
    },
    sortBy() {
      this.getOrders()
      this.updateTableFilters()
    },
    sortDesc() {
      this.getOrders()
      this.updateTableFilters()
    },
    itemsPerPage() {
      this.getOrders()
      this.updateTableFilters()
    },
    year() {
      this.getOrders()
      this.updateTableFilters()
    },
    month() {
      this.getOrders()
      this.updateTableFilters()
    },
  },
  methods: {
    loadTableFilters() {
      const { tableFilters } = this
      Object.keys(tableFilters)
        .forEach(key => {
          this[key] = tableFilters[key]
        })
    },
    updateTableFilters() {
      const data = {
        page: this.page,
        searches: this.searches,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        itemsPerPage: this.itemsPerPage,
        year: this.year,
        month: this.month,
        revenueSources: this.revenueSources,
      }
      this.$emit('update:tableFilters', data)
    },
    getStockCategories() {
      const handler = stockCategoryIndexHandler
      const repository = stockCategoryRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    getRevenueSources() {
      const handler = revenueSourceIndexHandler
      const repository = revenueSourceRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    getOrderStatuses() {
      const handler = orderStatusIndexHandler
      const repository = orderStatusRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    getCustomerIndex() {
      const handler = customerIndexHandler
      const repository = customerRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    initializeWebsocket() {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.dbStoreEvent)
        .listen('DBUpdateEvent', this.dbUpdateEvent)
    },
    exportDetailV1() {
      const handler = orderDetailExcelV1Handler
      const repository = orderDetailRepository.exportV1
      const params = Window.removeNullAttrs({
        customerId: this.exportItemsFilter.customerId,
        year: this.exportItemsFilter.year,
        month: this.exportItemsFilter.month,
        status: this.exportItemsFilter.status.join(','),
        stockCategory: this.exportItemsFilter.stockCategory.join(','),
        revenueSource: this.exportItemsFilter.revenueSource.join(','),
      })
      handler.setVue(this)
      handler.execute(repository, [params], ({ data }) => {
        const blob = new Blob([data], { type: 'application/xlsx' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `order_details_${new Date().getTime()}.xlsx`
        link.click()
        URL.revokeObjectURL(link.href)
      })
    },
    dbStoreEvent({ model, data }) {
      if (!model || this.$objectEmpty(data)) return
      if (model === 'Order') {
        this.getOrders()
      }
    },
    dbUpdateEvent({ model, data }) {
      if (!model || this.$objectEmpty(data)) return
      if (model === 'Order') {
        this.orderList = this.orderList.map(item => {
          if (item.id === data.id) return { ...item, ...data }
          return { ...item }
        })
      }
    },
    getOrders() {
      const handler = orderGetHandler
      const repository = orderRepository.index
      const params = this.getOrderIndexParams()
      handler.setVue(this)
      handler.execute(repository, [params], this.getOrderStatusOk)
    },
    getOrderStatusOk({ data }) {
      this.serverItemsLength = data.count
      this.orderList = data.list
    },
    getOrderIndexParams() {
      const { search, sortBy = 'invoice_number', sortDesc, itemsPerPage, page } = this
      const orderBy = `${sortBy.first() || 'invoice_number'},${sortDesc.first() ? 'desc' : 'asc'}`
      const offset = itemsPerPage * Math.abs(page - 1)
      const limit = itemsPerPage < 0 ? 1000 : itemsPerPage
      const { year, month } = this
      return {
        with: ['status', 'invoice', 'customer', 'details.stock'].join(','),
        has: ['customer'].join(','),
        withCount: ['invoice'].join(','),
        search,
        orderBy,
        offset,
        limit,
        year,
        month,
        revenueSource: this.revenueSources.join(','),
      }
    },
    getOrderApiRequestVarNames() {
      const BASE_NAME = 'order'
      return {
        loading: `${BASE_NAME}Getting`,
        list: `${BASE_NAME}List`,
        error: `${BASE_NAME}GetErrors`,
      }
    },
    detailAmount(args) {
      const { objectChangeKeyCase } = Window
      const { unitPrice = 0, quantity = 0, discount = 0 } = objectChangeKeyCase(args) || {}
      if (!quantity || !unitPrice) return
      return (quantity * (unitPrice - discount)).phpFormat()
    },
    deleteItem(removeItem) {
      this.searches = this.searches.filter(item => item !== removeItem)
    },
    balance(item) {
      const { paid = 0 } = item || {}
      const payable = item.details.reduce((total, item) => total + ((item.unit_price - item.discount) * item.quantity), 0)
      const balance = payable - paid
      return balance < 0 ? 0 : balance
    },
    async changeStatusToDelivered(id) {
      const confirm = await this.confirmOrderDeliver()
      if (!confirm) return
      const handler = orderDeliverHandler
      const repository = orderRepository.deliver
      handler.setVue(this)
      handler.execute(repository, [id])
    },
    confirmOrderDeliver() {
      const config = {
        title: 'Deliver',
        color: 'green',
        icon: 'mdi-truck-delivery-outline',
      }
      return this.$confirm('Are you sure you want to update this order to delivered?', config)
    },
  },
}
</script>
